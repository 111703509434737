import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000" >
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">

<path d="M6624 7668 c-75 -73 -145 -145 -155 -160 l-20 -28 38 -57 c20 -32 40
-60 43 -63 14 -13 80 -113 122 -185 85 -147 140 -327 158 -519 5 -49 13 -93
18 -98 4 -4 112 -7 238 -6 l229 3 -1 80 c-3 196 -87 500 -202 730 -93 186
-276 435 -320 435 -7 0 -74 -60 -148 -132z"/>
<path d="M4769 7517 c-53 -20 -108 -56 -162 -106 l-47 -44 -113 12 c-152 15
-242 14 -372 -4 -349 -49 -585 -152 -766 -335 -89 -89 -148 -185 -181 -290
-33 -109 -33 -282 1 -397 14 -46 28 -83 32 -83 15 0 18 32 9 81 -19 99 -12
177 23 280 64 186 166 302 355 406 220 121 584 191 852 163 118 -12 140 -20
140 -49 0 -87 112 -224 210 -256 67 -22 176 -17 240 12 212 95 252 402 71 548
-76 61 -215 91 -292 62z m157 -157 c58 -22 112 -120 100 -182 -25 -133 -182
-189 -285 -102 -50 42 -69 141 -38 201 17 31 63 77 87 86 26 9 108 7 136 -3z"/>
<path d="M6213 7247 c-84 -83 -156 -155 -159 -159 -3 -4 7 -26 24 -50 56 -82
93 -143 116 -193 25 -53 66 -203 66 -241 0 -55 -6 -54 235 -54 248 0 235 -4
235 72 0 102 -104 411 -186 552 -65 113 -152 226 -172 226 -4 0 -76 -69 -159
-153z"/>
<path d="M4256 7051 c-33 -11 -62 -31 -85 -59 -41 -47 -64 -57 -176 -72 -145
-20 -239 -42 -315 -73 -221 -91 -373 -240 -416 -407 -24 -93 -16 -222 17 -286
13 -26 30 -49 38 -52 10 -3 11 0 2 16 -16 29 -13 229 3 275 32 88 142 211 234
262 20 11 39 23 42 26 11 13 189 79 250 92 93 21 224 31 247 19 10 -6 34 -33
52 -59 50 -74 84 -93 162 -93 81 0 152 33 189 86 38 57 41 157 6 227 -27 55
-69 91 -127 107 -45 13 -65 12 -123 -9z m148 -122 c20 -23 26 -41 26 -77 -1
-79 -56 -120 -137 -102 -51 12 -73 41 -73 99 1 69 44 111 116 111 35 0 47 -5
68 -31z"/>
<path d="M5942 6984 c-46 -32 -46 -48 0 -140 23 -48 54 -132 68 -188 l25 -101
69 -3 68 -3 -7 43 c-21 139 -86 304 -146 373 -35 39 -45 42 -77 19z"/>
<path d="M4842 6418 c-19 -19 -14 -56 9 -68 16 -9 26 -8 45 5 39 26 26 75 -21
75 -12 0 -26 -5 -33 -12z"/>
<path d="M6830 6155 l0 -275 50 0 c38 0 50 4 50 15 0 8 7 15 15 15 8 0 15 -4
15 -9 0 -5 21 -14 46 -20 156 -40 289 101 244 258 -33 118 -166 177 -273 122
l-37 -19 0 94 0 94 -55 0 -55 0 0 -275z m273 14 c54 -41 55 -149 2 -183 -28
-19 -83 -21 -118 -5 -32 14 -47 49 -47 105 0 37 6 51 29 75 37 36 94 40 134 8z"/>
<path d="M5964 6261 c-52 -24 -74 -55 -74 -104 0 -72 29 -96 140 -117 77 -15
100 -26 100 -45 0 -38 -121 -46 -189 -12 -29 14 -29 14 -47 -25 -20 -45 -16
-50 47 -68 75 -21 125 -22 181 -6 93 27 136 92 103 155 -19 39 -55 57 -147 76
-76 16 -89 25 -81 56 9 33 99 36 174 5 12 -5 20 4 32 34 l16 41 -27 11 c-15 6
-63 13 -107 15 -63 3 -89 0 -121 -16z"/>
<path d="M6312 6128 c3 -167 9 -186 70 -229 42 -30 131 -31 182 -1 37 22 38
22 43 2 4 -17 14 -20 54 -20 l49 0 0 200 0 200 -54 0 -54 0 -4 -131 c-3 -129
-3 -131 -31 -155 -34 -29 -85 -32 -118 -5 -23 18 -24 26 -27 155 l-4 136 -55
0 -55 0 4 -152z"/>
<path d="M3540 6074 l0 -195 33 3 32 3 5 131 c6 167 23 194 117 194 45 0 58
-4 78 -26 24 -26 25 -32 25 -165 l0 -139 35 0 35 0 0 119 c0 132 9 167 47 195
38 28 118 26 147 -3 19 -19 21 -35 26 -164 l5 -142 33 -3 32 -3 0 142 c0 126
-2 145 -22 180 -26 47 -70 69 -137 69 -55 0 -94 -13 -128 -44 -23 -19 -23 -19
-45 1 -37 33 -64 43 -119 43 -38 0 -64 -7 -96 -25 l-43 -26 0 26 c0 21 -5 25
-30 25 l-30 0 0 -196z"/>
<path d="M4330 6075 l0 -195 35 0 35 0 0 126 c0 173 20 204 131 204 40 0 55
-5 74 -25 24 -23 25 -29 25 -165 l0 -140 35 0 35 0 0 137 c0 165 -15 209 -80
238 -53 24 -148 17 -190 -13 l-30 -23 0 26 c0 22 -4 25 -35 25 l-35 0 0 -195z"/>
<path d="M4840 6075 l0 -196 33 3 32 3 0 190 0 190 -32 3 -33 3 0 -196z"/>
<path d="M5125 6256 c-16 -8 -45 -29 -63 -48 -58 -61 -67 -143 -26 -226 29
-58 80 -95 147 -106 81 -15 209 50 177 89 -13 16 -50 21 -50 6 0 -14 -65 -41
-97 -41 -118 0 -178 158 -94 247 44 47 141 50 185 7 23 -24 24 -24 47 -5 23
19 23 20 6 39 -45 49 -163 69 -232 38z"/>
<path d="M5542 6255 c-36 -16 -63 -43 -89 -90 -20 -36 -22 -139 -4 -175 57
-109 194 -149 302 -88 41 23 48 38 25 57 -11 10 -23 8 -55 -8 -22 -12 -56 -21
-75 -21 -67 0 -133 48 -139 102 l-2 23 155 1 155 0 -1 51 c-2 65 -42 125 -97
148 -47 19 -131 19 -175 0z m157 -58 c29 -14 66 -72 56 -87 -8 -13 -242 -13
-250 0 -8 13 21 66 47 84 27 19 113 21 147 3z"/>
<path d="M7476 6205 c5 -11 10 -71 12 -134 3 -114 3 -115 -32 -186 -96 -195
-367 -345 -651 -362 l-101 -6 -29 36 c-17 19 -38 47 -48 61 -10 14 -37 36 -59
47 -80 41 -207 8 -265 -68 -24 -31 -28 -47 -31 -112 -3 -71 -1 -80 30 -131 40
-66 93 -100 158 -100 75 0 115 17 172 74 58 57 59 57 208 76 165 22 290 60
382 117 21 13 40 23 44 23 22 0 150 119 200 185 39 52 51 78 69 150 31 121 14
265 -37 319 -20 21 -27 25 -22 11z m-943 -647 c36 -19 46 -40 47 -95 0 -45 -4
-54 -30 -77 -89 -74 -192 -18 -177 97 10 72 93 111 160 75z"/>
<path d="M7627 6031 c-2 -11 -1 -39 4 -63 25 -129 0 -262 -76 -407 -55 -104
-162 -206 -290 -276 -27 -15 -54 -30 -60 -35 -17 -13 -182 -69 -265 -90 -183
-47 -435 -63 -593 -39 -74 12 -87 19 -87 55 0 64 -103 194 -184 234 -61 29
-210 29 -268 -1 -177 -90 -238 -312 -133 -479 76 -120 264 -176 390 -116 28
13 76 46 108 75 56 49 61 51 115 51 31 0 95 -5 142 -10 217 -25 637 58 809
159 29 17 55 31 58 31 18 0 195 146 229 187 21 27 41 50 45 53 33 26 99 180
119 278 18 86 8 249 -19 324 -12 32 -21 64 -21 73 0 21 -17 19 -23 -4z m-1589
-778 c23 -15 42 -33 42 -39 0 -7 5 -14 12 -16 7 -3 14 -31 16 -69 3 -53 0 -71
-17 -101 -63 -106 -197 -120 -275 -28 -73 88 -52 210 45 258 62 31 124 29 177
-5z"/>
<path d="M3506 5734 c-18 -46 10 -229 69 -459 44 -172 130 -357 253 -545 75
-116 175 -230 201 -230 9 0 86 70 171 155 l154 155 -19 32 c-10 18 -21 35 -25
38 -14 12 -121 171 -159 240 -84 148 -142 334 -161 514 -6 58 -15 103 -23 108
-6 4 -112 8 -233 8 -193 0 -223 -2 -228 -16z"/>
<path d="M4080 5735 c-44 -53 75 -449 192 -638 61 -100 136 -197 151 -197 7 0
84 70 171 154 l160 154 -51 79 c-28 43 -60 95 -72 115 -30 51 -77 194 -86 259
-12 98 12 89 -234 89 -184 0 -221 -2 -231 -15z"/>
<path d="M4636 5741 c-9 -15 20 -150 49 -224 52 -137 103 -217 139 -217 12 0
35 13 51 30 l28 29 -46 98 c-44 94 -77 198 -77 241 0 36 -27 52 -85 52 -30 0
-56 -4 -59 -9z"/>
<path d="M5734 4210 c-55 -22 -70 -95 -30 -135 33 -33 66 -39 110 -21 65 27
73 110 13 147 -34 20 -59 23 -93 9z"/>
<path d="M3487 3890 c-52 -13 -118 -48 -144 -76 -12 -13 -24 -24 -27 -24 -3 0
-6 21 -8 48 l-3 47 -62 3 -63 3 0 -386 0 -385 64 0 64 0 4 243 c3 237 4 244
29 292 15 30 42 62 68 80 38 27 52 30 124 33 104 4 148 -18 186 -93 25 -49 26
-54 29 -302 l4 -253 64 0 64 0 0 223 c0 245 7 290 56 354 41 54 100 76 193 71
91 -4 129 -27 163 -97 21 -43 23 -63 26 -298 l4 -253 64 0 64 0 0 258 c0 256
-5 299 -38 372 -35 76 -113 126 -220 141 -120 17 -239 -18 -312 -91 -42 -42
-42 -42 -57 -21 -27 39 -57 63 -108 87 -57 26 -170 38 -228 24z"/>
<path d="M5054 3890 c-63 -9 -130 -39 -168 -74 -15 -15 -31 -24 -35 -21 -3 4
-6 25 -6 48 l0 42 -62 3 -63 3 0 -386 0 -385 64 0 65 0 3 248 3 247 27 46 c15
25 45 58 68 74 34 24 54 29 119 33 94 5 136 -7 178 -51 56 -58 63 -101 63
-362 l0 -235 66 0 65 0 -3 278 c-3 274 -3 278 -29 332 -14 30 -41 70 -59 87
-60 58 -185 89 -296 73z"/>
<path d="M6350 3887 c-156 -37 -266 -143 -300 -289 -30 -131 9 -279 96 -363
124 -121 319 -156 470 -84 55 26 134 95 134 117 0 5 -20 23 -43 40 l-44 31
-44 -40 c-99 -90 -270 -87 -362 5 -55 55 -72 103 -72 201 0 80 3 95 28 140 35
61 74 94 141 117 100 34 195 15 265 -54 41 -39 42 -40 67 -23 41 27 64 49 64
61 0 23 -106 109 -156 126 -68 24 -177 30 -244 15z"/>
<path d="M7155 3886 c-130 -37 -196 -91 -251 -202 -29 -59 -38 -89 -42 -147
-8 -126 40 -255 121 -323 125 -105 336 -131 481 -60 54 27 106 67 106 82 -1 5
-16 27 -34 48 l-33 39 -39 -31 c-114 -90 -291 -80 -398 24 -38 37 -48 56 -61
112 l-7 32 311 0 311 0 1 23 c9 137 -45 263 -145 338 -85 64 -224 92 -321 65z
m203 -130 c57 -29 99 -74 118 -128 25 -72 40 -68 -232 -68 l-246 0 6 31 c15
78 75 149 147 175 60 22 154 17 207 -10z"/>
<path d="M5710 3505 l0 -385 65 0 65 0 0 385 0 385 -65 0 -65 0 0 -385z"/>
<path d="M3308 2914 c-30 -16 -44 -50 -33 -83 7 -24 43 -45 95 -56 19 -4 41
-12 48 -17 17 -15 15 -55 -4 -62 -24 -9 -90 -7 -118 4 -21 8 -26 6 -26 -5 0
-21 41 -35 101 -35 87 0 127 65 72 116 -14 13 -44 27 -66 31 -22 3 -49 15 -59
25 -17 17 -18 22 -5 41 11 17 24 22 58 21 24 0 52 -4 62 -8 12 -6 17 -4 17 8
0 34 -93 47 -142 20z"/>
<path d="M3610 2845 c0 -136 32 -185 120 -185 85 1 110 40 110 175 0 82 -2 96
-15 93 -13 -2 -16 -22 -18 -100 -2 -114 -17 -140 -81 -140 -65 0 -76 20 -76
139 0 96 -1 103 -20 103 -18 0 -20 -7 -20 -85z"/>
<path d="M4010 2796 l0 -136 83 0 c93 0 127 13 141 53 8 22 6 33 -13 58 -17
23 -20 34 -12 42 19 19 13 72 -10 93 -16 15 -40 20 -105 23 l-84 3 0 -136z
m164 89 c13 -10 17 -21 12 -39 -7 -30 -33 -39 -97 -34 -40 3 -44 6 -47 31 -6
49 3 57 60 57 30 0 61 -7 72 -15z m7 -115 c21 -12 26 -52 6 -68 -10 -9 -88
-16 -134 -13 -14 1 -19 72 -6 84 10 11 112 8 134 -3z"/>
<path d="M4401 2904 c-58 -48 -34 -103 54 -124 80 -19 101 -42 68 -75 -12 -12
-29 -16 -67 -13 -28 3 -59 8 -68 12 -13 6 -18 4 -18 -6 0 -22 44 -38 101 -38
124 0 133 124 10 146 -20 4 -44 11 -53 16 -22 11 -23 51 -3 68 15 12 75 10
108 -4 12 -6 17 -4 17 8 0 41 -104 48 -149 10z"/>
<path d="M4770 2912 c-42 -21 -84 -94 -75 -132 18 -80 65 -120 139 -120 52 0
96 17 96 37 0 15 -26 18 -35 3 -8 -13 -91 -13 -116 0 -24 13 -49 61 -49 95 0
36 28 81 57 94 34 15 85 14 109 -4 20 -14 34 -11 34 6 0 15 -58 39 -92 39 -18
0 -49 -8 -68 -18z"/>
<path d="M5087 2924 c-3 -5 -5 -65 -4 -134 2 -110 4 -125 20 -128 14 -3 17 4
17 37 l0 41 45 0 c41 0 47 -3 71 -40 15 -22 33 -40 40 -40 20 0 17 12 -11 49
l-24 34 29 27 c44 41 37 108 -13 140 -26 16 -162 27 -170 14z m151 -40 c15
-10 22 -25 22 -49 0 -44 -29 -65 -92 -65 l-48 0 0 65 0 65 48 0 c27 0 57 -7
70 -16z"/>
<path d="M5460 2928 c0 -2 -1 -61 -1 -133 -1 -112 1 -130 15 -133 14 -3 16 12
16 132 0 114 -2 136 -15 136 -8 0 -15 -1 -15 -2z"/>
<path d="M5664 2917 c-2 -7 -3 -67 -2 -132 3 -105 5 -120 21 -123 14 -3 17 4
17 37 l0 41 45 0 c55 0 110 21 124 48 26 49 4 105 -51 126 -48 20 -147 21
-154 3z m154 -33 c15 -10 22 -25 22 -49 0 -49 -21 -65 -86 -65 l-54 0 0 65 0
65 48 0 c27 0 57 -7 70 -16z"/>
<path d="M5980 2915 c0 -11 11 -15 45 -15 l45 0 0 -120 c0 -113 1 -120 20
-120 19 0 20 7 20 120 l0 120 46 0 c32 0 44 4 42 13 -3 8 -37 13 -111 15 -91
2 -107 0 -107 -13z"/>
<path d="M6337 2923 c-4 -3 -7 -64 -7 -135 0 -121 1 -128 20 -128 19 0 20 7
20 135 0 106 -3 135 -13 135 -8 0 -17 -3 -20 -7z"/>
<path d="M6595 2916 c-41 -18 -61 -43 -74 -93 -9 -34 -9 -49 2 -75 25 -57 60
-81 125 -85 69 -5 110 16 135 70 23 48 21 88 -6 128 -40 61 -116 84 -182 55z
m142 -51 c28 -32 31 -99 7 -134 -36 -51 -122 -57 -168 -11 -26 26 -30 118 -6
141 48 48 128 50 167 4z"/>
<path d="M6957 2923 c-4 -3 -7 -64 -7 -135 0 -118 2 -129 18 -126 14 3 17 16
18 91 0 48 3 89 6 92 3 3 40 -37 82 -90 43 -52 84 -95 92 -95 11 0 14 24 14
136 0 119 -2 135 -16 132 -13 -2 -17 -19 -18 -92 0 -50 -5 -91 -11 -92 -5 -2
-43 39 -84 91 -73 94 -81 101 -94 88z"/>
<path d="M7373 2910 c-31 -18 -38 -34 -33 -70 3 -26 33 -47 91 -64 60 -17 76
-35 59 -67 -12 -21 -79 -27 -124 -9 -37 14 -43 -6 -7 -25 52 -26 140 -16 163
19 10 15 10 58 -1 75 -5 8 -40 24 -77 36 -57 18 -70 26 -72 46 -3 14 3 28 14
36 21 15 90 17 99 3 9 -15 35 -12 35 4 0 16 -50 36 -89 36 -14 0 -41 -9 -58
-20z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
